<template>
  <div>
    <div class="common-page-filters d-flex align-center mx-auto mb-4">
      <div class="d-flex align-center ml-auto">
        <UiSearch
          class="common-page-filters-search"
          inputClass="rounded mr-1"
          @onSearch="
            (val) => {
              searchTask(val);
            }
          "
        />
      </div>
    </div>
    <div class="d-flex">
      <div class="mr-3 select-wrapper">
        <div class="mb-1 gray-100--text text-captions-1">Space</div>
        <v-autocomplete
          v-model="spacesFilter"
          :items="getSpaces"
          :item-text="'title'"
          item-value="id"
          :return-object="false"
          multiple
          persistent-hint
          hide-details
          :hide-selected="false"
          dense
          outlined
          append-icon=""
          attach=".filter-select-space"
          :menu-props="{ contentClass: 'filter-autocomplete-menu' }"
          @change="updateFilter($config.tasksConfig.filter_slugs.space)"
          @update:search-input="
            highlightSelection(
              $event,
              getSpaces,
              $config.tasksConfig.filter_slugs.space
            )
          "
          placeholder="Select Space"
          class="filter-select filter-select-space"
        >
          <template #prepend-item>
            <div class="px-4">
              <v-chip
                v-for="chip in selectedSpaces"
                class="filter-chip ml-0 mr-1 px-1"
                :key="chip.id"
              >
                <template #default>
                  <div class="d-flex align-center max-width-100">
                    <div class="text-captions-2 accent-100--text text-truncate">
                      {{ chip.title }}
                    </div>
                    <v-btn
                      class="ml-1"
                      height="12"
                      icon
                      width="12"
                      @click="
                        removeFilter(
                          'spacesFilter',
                          $config.tasksConfig.filter_slugs.space,
                          chip.id
                        )
                      "
                    >
                      <IconCancel
                        width="10"
                        height="10"
                        class="accent-100--text"
                      />
                    </v-btn>
                  </div>
                </template>
              </v-chip>
            </div>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
              <template #default="{ active }">
                <v-list-item-action class="mt-2 mb-2">
                  <UiCheckbox checkbox-form="square" :input-value="active" />
                </v-list-item-action>
                <v-list-item-icon
                  class="d-flex align-center mt-0 mb-0 list-item-icon"
                >
                  <v-sheet
                    rounded="circle"
                    :color="item.color"
                    width="14"
                    height="14"
                  ></v-sheet>
                </v-list-item-icon>
                <v-list-item-content>
                  <div class="d-flex text-captions-1 fill-width">
                    <div
                      v-html="item.htmlTitle || item.title"
                      class="fill-width text-truncate"
                    ></div>
                  </div>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
          <template v-slot:selection="{}">
            <div class="selected-result d-flex align-center text-captions-1">
              Spaces
              <div
                class="selected-count d-flex align-center justify-center ml-1 gray-60--text"
              >
                {{ spacesFilter.length }}
              </div>
            </div>
          </template>
          <template #append>
            <IconChevronDown
              width="16"
              class="icon-transition"
              style="margin-top: -1px"
            />
          </template>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> No results matching </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>
      <div class="mr-3 select-wrapper">
        <div class="mb-1 gray-100--text text-captions-1">Assigned To</div>
        <v-autocomplete
          v-model="assignedToFilter"
          :items="filtersState[$config.tasksConfig.filter_slugs.assignee].data"
          :loading="
            filtersState[$config.tasksConfig.filter_slugs.assignee].loading
          "
          :item-text="'title'"
          item-value="id"
          :return-object="false"
          multiple
          persistent-hint
          hide-details
          :hide-selected="false"
          dense
          outlined
          append-icon=""
          attach=".filter-select-assigned"
          :menu-props="{ contentClass: 'filter-autocomplete-menu' }"
          @change="updateFilter($config.tasksConfig.filter_slugs.assignee)"
          @update:search-input="
            highlightSelection(
              $event,
              filtersState[$config.tasksConfig.filter_slugs.assignee].data,
              $config.tasksConfig.filter_slugs.assignee
            )
          "
          placeholder="Assignee"
          class="filter-select filter-select-assigned"
        >
          <template #prepend-item>
            <div class="px-4">
              <v-chip
                v-for="chip in selectedAssignee"
                :key="chip.id"
                class="filter-chip ml-0 mr-1 px-1"
              >
                <template #default>
                  <div class="d-flex align-center max-width-100">
                    <div class="text-captions-2 accent-100--text text-truncate">
                      {{ chip.title }}
                    </div>
                    <v-btn
                      class="ml-1"
                      height="12"
                      icon
                      width="12"
                      @click="
                        removeFilter(
                          'assignedToFilter',
                          $config.tasksConfig.filter_slugs.assignee,
                          chip.id
                        )
                      "
                    >
                      <IconCancel
                        width="10"
                        height="10"
                        class="accent-100--text"
                      />
                    </v-btn>
                  </div>
                </template>
              </v-chip>
            </div>
          </template>
          <template v-slot:selection="{}">
            <div class="selected-result d-flex align-center text-captions-1">
              Assignee
              <div
                class="selected-count d-flex align-center justify-center ml-1 gray-60--text"
              >
                {{ assignedToFilter.length }}
              </div>
            </div>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
              <template #default="{ active }">
                <v-list-item-action class="mt-2 mb-2">
                  <UiCheckbox checkbox-form="square" :input-value="active" />
                </v-list-item-action>
                <v-list-item-content>
                  <div class="d-flex text-captions-1 fill-width">
                    <div
                      v-html="item.htmlTitle || item.title"
                      class="fill-width text-truncate"
                    ></div>
                  </div>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
          <template #append>
            <IconChevronDown
              width="16"
              class="icon-transition"
              style="margin-top: -1px"
            />
          </template>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> No results matching </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:append-item>
            <div
              v-intersect="
                (entries, observer, isIntersecting) =>
                  endIntersect(
                    entries,
                    observer,
                    isIntersecting,
                    $config.tasksConfig.filter_slugs.assignee
                  )
              "
              class="d-flex justify-center"
            >
              <v-progress-circular
                v-show="
                  filtersState[$config.tasksConfig.filter_slugs.assignee]
                    .loading
                "
                :size="20"
                color="accent"
                indeterminate
              />
            </div>
          </template>
        </v-autocomplete>
      </div>
      <div class="mr-3 select-wrapper">
        <div class="mb-1 gray-100--text text-captions-1">Due Date</div>
        <v-autocomplete
          v-model="dateFilter"
          :items="filtersState[$config.tasksConfig.filter_slugs.due_date].data"
          :loading="
            filtersState[$config.tasksConfig.filter_slugs.due_date].loading
          "
          :item-text="'title'"
          item-value="id"
          :return-object="false"
          multiple
          persistent-hint
          hide-details
          :hide-selected="false"
          dense
          outlined
          append-icon=""
          attach=".filter-select-due_date"
          :menu-props="{ contentClass: 'filter-autocomplete-menu' }"
          @change="updateFilter($config.tasksConfig.filter_slugs.due_date)"
          @update:search-input="
            highlightSelection(
              $event,
              filtersState[$config.tasksConfig.filter_slugs.due_date].data,
              $config.tasksConfig.filter_slugs.due_date
            )
          "
          placeholder="Select Due Date"
          class="filter-select filter-select-due_date"
        >
          <template #prepend-item>
            <div class="px-4">
              <v-chip
                v-for="chip in selectedDates"
                :key="chip.id"
                class="filter-chip ml-0 mr-1 px-1"
              >
                <template #default>
                  <div class="d-flex align-center max-width-100">
                    <div class="text-captions-2 accent-100--text text-truncate">
                      {{ chip.title }}
                    </div>
                    <v-btn
                      class="ml-1"
                      height="12"
                      icon
                      width="12"
                      @click="
                        removeFilter(
                          'dateFilter',
                          $config.tasksConfig.filter_slugs.due_date,
                          chip.id
                        )
                      "
                    >
                      <IconCancel
                        width="10"
                        height="10"
                        class="accent-100--text"
                      />
                    </v-btn>
                  </div>
                </template>
              </v-chip>
            </div>
          </template>
          <template v-slot:selection="{}">
            <div class="selected-result d-flex align-center text-captions-1">
              Due Date
              <div
                class="selected-count d-flex align-center justify-center ml-1 gray-60--text"
              >
                {{ dateFilter.length }}
              </div>
            </div>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
              <template #default="{ active }">
                <v-list-item-action class="mt-2 mb-2">
                  <UiCheckbox checkbox-form="square" :input-value="active" />
                </v-list-item-action>
                <v-list-item-content>
                  <div class="d-flex text-captions-1 fill-width">
                    <div
                      v-html="item.htmlTitle || item.title"
                      class="fill-width text-truncate"
                    ></div>
                  </div>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
          <template #append>
            <IconChevronDown
              width="16"
              class="icon-transition"
              style="margin-top: -1px"
            />
          </template>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> No results matching </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:append-item>
            <div
              v-intersect="
                (entries, observer, isIntersecting) =>
                  endIntersect(
                    entries,
                    observer,
                    isIntersecting,
                    $config.tasksConfig.filter_slugs.due_date
                  )
              "
              class="d-flex justify-center"
            >
              <v-progress-circular
                v-show="
                  filtersState[$config.tasksConfig.filter_slugs.due_date]
                    .loading
                "
                :size="20"
                color="accent"
                indeterminate
              />
            </div>
          </template>
        </v-autocomplete>
      </div>

      <div class="mr-3 select-wrapper">
        <div class="mb-1 gray-100--text text-captions-1">Status</div>
        <v-autocomplete
          v-model="statusesFilter"
          :items="filtersState[$config.tasksConfig.filter_slugs.status].data"
          :loading="
            filtersState[$config.tasksConfig.filter_slugs.status].loading
          "
          :item-text="'title'"
          item-value="id"
          :return-object="false"
          multiple
          persistent-hint
          hide-details
          :hide-selected="false"
          dense
          outlined
          append-icon=""
          attach=".filter-select-statuses"
          :menu-props="{ contentClass: 'filter-autocomplete-menu' }"
          @change="updateFilter($config.tasksConfig.filter_slugs.status)"
          @update:search-input="
            highlightSelection(
              $event,
              filtersState[$config.tasksConfig.filter_slugs.status].data,
              $config.tasksConfig.filter_slugs.status
            )
          "
          placeholder="Select Status"
          class="filter-select filter-select-statuses"
        >
          <template #prepend-item>
            <div class="px-4">
              <v-chip
                v-for="chip in selectedStatuses"
                :key="chip.id"
                class="filter-chip ml-0 mr-1 px-1"
              >
                <template #default>
                  <div class="d-flex align-center max-width-100">
                    <div class="text-captions-2 accent-100--text text-truncate">
                      {{ chip.title }}
                    </div>
                    <v-btn
                      class="ml-1"
                      height="12"
                      icon
                      width="12"
                      @click="
                        removeFilter(
                          'statusesFilter',
                          $config.tasksConfig.filter_slugs.status,
                          chip.id
                        )
                      "
                    >
                      <IconCancel
                        width="10"
                        height="10"
                        class="accent-100--text"
                      />
                    </v-btn>
                  </div>
                </template>
              </v-chip>
            </div>
          </template>
          <template v-slot:selection="{}">
            <div class="selected-result d-flex align-center text-captions-1">
              Statuses
              <div
                class="selected-count d-flex align-center justify-center ml-1 gray-60--text"
              >
                {{ statusesFilter.length }}
              </div>
            </div>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
              <template #default="{ active }">
                <v-list-item-action class="mt-2 mb-2">
                  <UiCheckbox checkbox-form="square" :input-value="active" />
                </v-list-item-action>
                <v-list-item-content>
                  <div class="d-flex text-captions-1 fill-width">
                    <div
                      v-html="item.htmlTitle || item.title"
                      class="fill-width text-truncate"
                    ></div>
                  </div>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
          <template #append>
            <IconChevronDown
              width="16"
              class="icon-transition"
              style="margin-top: -1px"
            />
          </template>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> No results matching </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:append-item>
            <div
              v-intersect="
                (entries, observer, isIntersecting) =>
                  endIntersect(
                    entries,
                    observer,
                    isIntersecting,
                    $config.tasksConfig.filter_slugs.status
                  )
              "
              class="d-flex justify-center"
            >
              <v-progress-circular
                v-show="
                  filtersState[$config.tasksConfig.filter_slugs.status].loading
                "
                :size="20"
                color="accent"
                indeterminate
              />
            </div>
          </template>
        </v-autocomplete>
      </div>
      <div class="mr-3 select-wrapper">
        <div class="mb-1 gray-100--text text-captions-1">Priority</div>
        <v-autocomplete
          v-model="priorityFilter"
          :items="filtersState[$config.tasksConfig.filter_slugs.priority].data"
          :loading="
            filtersState[$config.tasksConfig.filter_slugs.priority].loading
          "
          :item-text="'title'"
          item-value="id"
          :return-object="false"
          multiple
          persistent-hint
          hide-details
          :hide-selected="false"
          dense
          outlined
          append-icon=""
          attach=".filter-select-priority"
          :menu-props="{ contentClass: 'filter-autocomplete-menu' }"
          @change="updateFilter($config.tasksConfig.filter_slugs.priority)"
          @update:search-input="
            highlightSelection(
              $event,
              filtersState[$config.tasksConfig.filter_slugs.priority].data,
              $config.tasksConfig.filter_slugs.priority
            )
          "
          placeholder="Select Priority"
          class="filter-select filter-select-priority"
        >
          <template #prepend-item>
            <div class="px-4">
              <v-chip
                v-for="chip in selectedPriorities"
                :key="chip.id"
                class="filter-chip ml-0 mr-1 px-1"
              >
                <template #default>
                  <div class="d-flex align-center max-width-100">
                    <div class="text-captions-2 accent-100--text text-truncate">
                      {{ chip.title }}
                    </div>
                    <v-btn
                      class="ml-1"
                      height="12"
                      icon
                      width="12"
                      @click="
                        removeFilter(
                          'priorityFilter',
                          $config.tasksConfig.filter_slugs.priority,
                          chip.id
                        )
                      "
                    >
                      <IconCancel
                        width="10"
                        height="10"
                        class="accent-100--text"
                      />
                    </v-btn>
                  </div>
                </template>
              </v-chip>
            </div>
          </template>
          <template v-slot:selection="{}">
            <div class="selected-result d-flex align-center text-captions-1">
              Priorities
              <div
                class="selected-count d-flex align-center justify-center ml-1 gray-60--text"
              >
                {{ priorityFilter.length }}
              </div>
            </div>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
              <template #default="{ active }">
                <v-list-item-action class="mt-2 mb-2">
                  <UiCheckbox checkbox-form="square" :input-value="active" />
                </v-list-item-action>
                <v-list-item-content>
                  <div class="d-flex text-captions-1 fill-width">
                    <div
                      v-html="item.htmlTitle || item.title"
                      class="fill-width text-truncate"
                    ></div>
                  </div>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
          <template #append>
            <IconChevronDown
              width="16"
              class="icon-transition"
              style="margin-top: -1px"
            />
          </template>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title> No results matching </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:append-item>
            <div
              v-intersect="
                (entries, observer, isIntersecting) =>
                  endIntersect(
                    entries,
                    observer,
                    isIntersecting,
                    $config.tasksConfig.filter_slugs.priority
                  )
              "
              class="d-flex justify-center"
            >
              <v-progress-circular
                v-show="
                  filtersState[$config.tasksConfig.filter_slugs.priority]
                    .loading
                "
                :size="20"
                color="accent"
                indeterminate
              />
            </div>
          </template>
        </v-autocomplete>
      </div>
      <div class="mt-4">
        <UiBtn
          height="32"
          color="accent"
          class="font-weight-regular"
          text
          @click="clearAllFilters"
        >
          Clear all filters
        </UiBtn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBtn from "@/components/UI/UiBtn.vue";
import UiCheckbox from "@/components/UI/UiCheckbox.vue";
import { highlightText } from "@/utils/helpers";
import UiSearch from "@/components/UI/UiSearch.vue";

export default {
  name: "TasksFilter",
  components: {
    UiSearch,
    UiCheckbox,
    UiBtn,
    IconChevronDown: () => import("@/components/icons/IconChevronDown"),
    IconCancel: () => import("@/components/icons/IconCancel"),
  },
  props: {
    filter: {
      type: Object,
      required: true,
    },
    filtersState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      debounce: null,
      search: "",
      debounceSearch: null,
    };
  },
  computed: {
    ...mapGetters(["getSpaces"]),
    spacesFilter: {
      set(val) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            [this.$config.tasksConfig.filter_slugs.space]: val,
          },
        });
      },
      get() {
        return this.filter.space;
      },
    },

    statusesFilter: {
      set(val) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            [this.$config.tasksConfig.filter_slugs.status]: val,
          },
        });
      },
      get() {
        return this.filter.status;
      },
    },
    priorityFilter: {
      set(val) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            [this.$config.tasksConfig.filter_slugs.priority]: val,
          },
        });
      },
      get() {
        return this.filter.priority;
      },
    },
    assignedToFilter: {
      set(val) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            [this.$config.tasksConfig.filter_slugs.assignee]: val,
          },
        });
      },
      get() {
        return this.filter.assignee;
      },
    },
    dateFilter: {
      set(val) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            [this.$config.tasksConfig.filter_slugs.due_date]: val,
          },
        });
      },
      get() {
        return this.filter.due_date;
      },
    },
    selectedSpaces() {
      return this.getSpaces.filter((el) => this.spacesFilter.includes(el.id));
    },
    selectedAssignee() {
      return this.filtersState.assignee.data.filter((el) =>
        this.assignedToFilter.includes(el.id)
      );
    },

    selectedStatuses() {
      return this.filtersState.status.data.filter((el) =>
        this.statusesFilter.includes(el.id)
      );
    },
    selectedDates() {
      return this.filtersState.due_date.data.filter((el) =>
        this.dateFilter.includes(el.id)
      );
    },
    selectedPriorities() {
      return this.filtersState.priority.data.filter((el) =>
        this.priorityFilter.includes(el.id)
      );
    },
  },
  methods: {
    updateFilter(type) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.$route.query.page && this.$route.query.page !== "1") {
          await this.$router.replace({
            query: { ...this.$route.query, page: "1" },
          });
        }
        this.$emit("updateFilter", type);
      }, 800);
    },
    clearAllFilters() {
      // eslint-disable-next-line no-prototype-builtins
      if (
        Object.values(this.$config.tasksConfig.filter_slugs).find(
          (el) =>
            typeof this.$route.query[el] === "number" ||
            this.$route.query[el]?.length
        )
      ) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            page: "1",
            [this.$config.tasksConfig.filter_slugs.space]: [],
            [this.$config.tasksConfig.filter_slugs.status]: [],
            [this.$config.tasksConfig.filter_slugs.priority]: [],
            [this.$config.tasksConfig.filter_slugs.assignee]: [],
            [this.$config.tasksConfig.filter_slugs.due_date]: [],
          },
        });
        this.$emit("updateData");
        this.$emit("refetchFilters");
      }
    },
    endIntersect(entries, observer, isIntersecting, type) {
      if (
        isIntersecting &&
        !this.filtersState[type].loading &&
        this.filtersState[type].meta?.total >
          this.filtersState[type].data.length &&
        !this.filtersState[type].search
      ) {
        this.filtersState[type].page += 1;
        this.$emit("loadMoreFilters", { type });
      }
    },
    highlightSelection($event, list, type) {
      this.filtersState[type].search = $event;
      list.forEach((item) => {
        item.htmlTitle = highlightText($event || "", item.title || "");
      });
      clearTimeout(this.debounceSearch);
      this.debounceSearch = setTimeout(() => {
        this.$emit("loadMoreFilters", { search: $event, type });
      }, 600);
      this.updateFilter(type);
    },
    removeFilter(name, type, id) {
      this[name] = this[name].filter((item) => item !== id);
      this.updateFilter(type);
    },
    searchTask(val) {
      this.updateFilter(this.$config.tasksConfig.filter_slugs.search);
      this.$router.replace({
        query: {
          ...this.$route.query,
          [this.$config.tasksConfig.filter_slugs.search]: val,
        },
      });
      this.search = val;

      this.$router.$updateQueryParams({ search: val });
    },
  },
};
</script>

<style scoped lang="scss">
.select-wrapper {
  max-width: 150px;
}
.selected-count {
  width: 20px;
  height: 20px;
  background-color: var(--v-gray-30-base);
  border-radius: 50%;
}
.selected-result {
  padding: 3px 0;
}
.filter-select {
  :deep(.v-select__selections) {
    padding: 2px 0 !important;
    .selected-result {
      &:not(:first-child) {
        display: none !important;
      }
    }
    input {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
.list-item-icon {
  height: 100% !important;
}

.max-width-100 {
  max-width: 100%;
}
</style>
