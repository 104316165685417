<template>
  <div>
    <div class="tasks-page">
      <PageHeader>
        <div class="d-flex justify-space-between">
          <h1 class="heading">Tasks Dashboard</h1>
          <v-btn
            v-if="project"
            class="ml-2"
            color="primary"
            outlined
            fab
            height="32"
            style="box-shadow: 0 4px 4px rgb(0, 0, 0, 0.05)"
            width="120"
            @click="openCreateTaskModal()"
          >
            <v-icon class="primary--text mr-2" size="14">fa-plus</v-icon>
            New Task
          </v-btn>
        </div>
      </PageHeader>

      <v-container>
        <TaskTable
          ref="tasksTable"
          @selectActiveTask="openEditTaskModal"
        ></TaskTable>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageHeader from "@/components/layout/PageHeader";
import TaskTable from "@/views/tasks/TaskTable";

export default {
  name: "TasksPage",
  components: {
    TaskTable,
    PageHeader,
  },
  data() {
    return {
      project: null,
      activeTask: null,
    };
  },
  computed: {
    ...mapGetters(["getProject", "getProjectsList", "getModal"]),
  },
  created() {
    this.loadProjects();
  },
  methods: {
    async loadProjects() {
      try {
        const projects = await this.$api.project.list({ count: 10 });
        await this.$store.dispatch("setProjects", projects.data);
        await this.$store.dispatch("setProject", projects.data[0]);
        this.project = projects.data[0];
      } catch (error) {
        console.error(error);
      }
    },
    async openCreateTaskModal() {
      await this.$store.dispatch("openModal", {
        modalName: "createNewTaskModal",
        data: {
          type: "create",
          taskData: {
            id: null,
            project_id: null,
            projectList: [],
            parent: { id: null },
            isDashboard: true,
          },
        },
        handlers: {
          onCreate: () => {
            this.$refs.tasksTable.getInfo();
          },
          onClose: () => {},
        },
      });
    },
    async openEditTaskModal(task) {
      if (!task) {
        return;
      }
      await this.$store.dispatch("openModal", {
        modalName: "createNewTaskModal",
        data: {
          type: task.nameFocus ? "create" : "edit",
          taskData: {
            ...task,
            name: task.nameFocus ? '' : task.title,
            space_id: task.space.id,
            project_id: task.project?.id,
            projectList: [],
            isDashboard: true,
            project_tag_id: task?.project_tag_id,
            priority: task?.priority.id,
            assignee_id: task?.assigned_to?.id,
            status: task?.status.id,
            project_style_id: task?.style_id || task?.project_style_id,
            project_module_id: task?.project_module_id,
            parent: {id: task?.parent?.id || null},
          },
          formFocusElements: {
            assigneeFocus: !!task.assigneeFocus,
            nameFocus: !!task.nameFocus,
          },
        },
        handlers: {
          onUpdate: () => {
            this.$refs.tasksTable.getInfo();
          },
          onClose: () => {},
        },
      });
    },
  },
};
</script>
