<template>
  <div>
    <UiMenu bottom left offset-y :nudge-bottom="2" :min-width="156">
      <template v-slot:activator="{ on, attrs }">
        <UiBtn v-bind="attrs" v-on="on" @click.prevent icon color="gray-60">
          <IconDotsH width="18" />
        </UiBtn>
      </template>
      <v-list nav dense color="gray-10">
        <v-list-item @click="openProject">
          <IconEye width="18" class="mr-2 gray-60--text"></IconEye>
          <v-list-item-title
            class="font-weight-medium text-captions-1 text_color_19--text"
            >Open Project</v-list-item-title
          >
        </v-list-item>
        <v-list-item @click="assignToTaskFormField()">
          <IconSendMessage
            width="18"
            class="mr-2 gray-60--text"
          ></IconSendMessage>

          <v-list-item-title class="font-weight-medium text_color_19--text"
            >Assign to</v-list-item-title
          >
        </v-list-item>

        <v-list-item v-if="task && !task.parent" @click="createSubTask">
          <IconDraw width="18" class="mr-2 gray-60--text"></IconDraw>

          <v-list-item-title class="font-weight-medium text_color_19--text"
            >Create sub task</v-list-item-title
          >
        </v-list-item>
        <v-list-item @click="deleteTask">
          <IconDelete width="18" class="mr-2 gray-60--text"></IconDelete>
          <v-list-item-title
            class="font-weight-medium text-captions-1 text_color_19--text"
            >Delete</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </UiMenu>
  </div>
</template>

<script>
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";
import projectCompleteMixin from "@/mixins/projectCompleteMixin";
import { mapGetters } from "vuex";
import router from "@/router";

export default {
  name: "TaskTableMenu",
  components: {
    IconDotsH: () => import("@/components/icons/IconDotsH"),
    UiMenu,
    UiBtn,
    IconDelete: () => import("@/components/icons/IconDelete"),
    IconEye: () => import("@/components/icons/IconEye"),
    IconSendMessage: () => import("@/components/icons/IconSendMessage"),
    IconDraw: () => import("@/components/icons/IconDraw"),
  },
  mixins: [projectCompleteMixin],
  props: {
    task: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      filters: {
        created_at: "all",
        order: "asc",
        search: "",
      },
      taskFormData: {
        showForm: false,
        assigneeFocus: false,
        nameFocus: false,
        isPermanent: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getAuthId",
      "getPermission",
      "getProjectPermissionData",
      "getProject",
    ]),
  },
  methods: {
    closeTaskForm() {
      this.taskFormData = {
        showForm: false,
        assigneeFocus: false,
        nameFocus: false,
        isPermanent: false,
      };
    },
    openProject() {
      router.push(this.buildRedirectLink(this.task));
    },
    buildRedirectLink(task) {
      if (task.file_id && task.gallery_group_id && task.style_id) {
        return `/project/${task.project.id}/modules/design-view?module_id=${task.module_id}&style_id=${task.style_id}&file_id=${task.file_id}&gallery_group_id=${task.gallery_group_id}`;
      } else {
        return `/project/${task.project.id}/modules/design`;
      }
    },
    createSubTask() {
      const taskData = {
        ...this.task,
        project_style_id: this.task?.style_id,
        nameFocus: true,
        id: null,
        project_id: this.task?.project.id,
        project_tag_id: this.task?.tag_id,
        project_module_id: this.task?.module_id,
        parent: { id: this.task.id },
      };
      this.$emit("openModal", taskData);
    },
    assignToTaskFormField() {
      const taskData = {
        ...this.task,
        assigneeFocus: true,
        id: this.task?.id || null,
        project_id: this.task?.project.id,
        project_tag_id: this.task?.tag_id,
        project_style_id: this.task?.style_id,
        project_module_id: this.task?.module_id,
        parent: { id: this.task?.parent?.id || null },
      };
      this.$emit("openModal", taskData);
    },
    deleteTask() {
      this.$store.dispatch("openModal", {
        modalName: "confirmModal",
        data: {
          title: `Are you sure you want to delete <br> “${this.task.title}“?`,
          confirmBtnText: "Delete",
        },
        handlers: {
          onConfirm: () => {
            console.log(this.task);
            this.$api.task
              .delete(this.task.project.id, this.task.id)
              .then(() => {
                this.$toast.open({
                  message: "Task deleted successfully",
                  type: "success",
                  position: "top-right",
                });
                this.$emit("update");
              })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                this.$store.dispatch("closeModal", "confirmModal");
              });
          },
          onCancel: () => {
            this.$store.dispatch("closeModal", "confirmModal");
          },
        },
      });
    },
  },
};
</script>
