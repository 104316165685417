<template>
  <div
    ref="tasks_body"
    class="project-side-drawer-tasks pb-8"
    @scroll="loadMore"
  >
    <TasksFilter
      :filter="queryParams"
      :filtersState="filtersState"
      @updateFilter="updateFilter"
      @loadMoreFilters="loadFilters"
      @updateData="getInfo(false)"
      @refetchFilters="refetchFilters"
    />
    <v-data-table
      v-model="selected"
      :headers="convertedHeaders"
      :items="tableItems"
      :items-per-page="tableItems.length"
      :expanded.sync="expanded"
      :loading="loadingData"
      :custom-sort="customSort"
      item-key="id"
      show-select
      must-sort
      hide-default-footer
      @click:row="(event, row) => $emit('selectActiveTask', row.item)"
      :hide-default-header="false"
      class="task-table mt-6"
    >
      <template v-slot:[`header.data-table-select`]="{}">
        <div class="ml-2"></div>
      </template>
      <template v-slot:item.data-table-select="{ isSelected, select, item }">
        <div class="ml-2">
          <UiSelectedIndicator
            :selected="isSelected"
            @click.stop="
              setAsComplete(item.id, item.project.id);
              isSelected
                ? (selected = selected.filter((el) => el.id !== item.id))
                : select($event);
            "
          />
        </div>
      </template>
      <template v-slot:item.title="{ item }">
        <div class="d-flex align-center">
          <span v-if="item.title" class="project-title gray-100--text">{{
            item?.title
          }}</span>
        </div>
      </template>
      <template v-slot:item.assignee="{ item }">
        <div class="d-flex ml-3">
          <v-tooltip top color="accent">
            <template v-slot:activator="{ on, attrs }">
              <v-avatar
                v-on="on"
                v-bind="attrs"
                size="24"
                :class="{ 'custom-border': !item.assigned_to.avatar }"
              >
                <img
                  v-if="item.assigned_to.avatar"
                  :src="item.assigned_to.avatar.url"
                  alt=""
                />

                <span v-else class="gray-80--text">{{
                  item.assigned_to.first_name[0]
                }}</span>
              </v-avatar>
            </template>
            <span class="white--text font-weight-medium"
              >{{ item.assigned_to.first_name }}
              {{ item.assigned_to.last_name }}</span
            >
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.start_date="{ item }">
        <div v-if="item?.start_date" class="gray-60--text font-weight-bold">
          {{ formatDate(item.start_date) }}
        </div>
      </template>
      <template v-slot:item.due_date="{ item }">
        <v-chip
          v-if="item?.due_date"
          class="due-date-label"
          :color="dueDaysLeft(item.due_date).backgroundColor"
          label
        >
          <span
            class="text-captions-2"
            :class="[dueDaysLeft(item.due_date).textColor]"
            >{{ dueDaysLeft(item.due_date).prefix }}</span
          >
          <span
            class="dark text-captions-2 font-weight-bold ml-1"
            :class="[dueDaysLeft(item.due_date).textColor]"
          >
            {{ dueDaysLeft(item.due_date).value }}
            {{ dueDaysLeft(item.due_date).suffix }}
          </span>
        </v-chip>
      </template>
      <template v-slot:item.space="{ item }">
        <UIChip
          v-if="item.space"
          class="mr-2 px-0"
          chipHeight="18px"
          showDot
          :text-color="item.space.color"
          backgroundColor="transparent"
        >
          <span class="text-captions-2 font-weight-bold">{{
            item.space.title
          }}</span>
        </UIChip>
      </template>
      <template v-slot:item.status="{ item }">
        <div class="d-flex align-center text_color_19--text font-weight-medium">
          <IconCircleFilled
            width="14"
            :class="[
              `mr-2 ${$config.tasks.statusColors[item.status.id]}--text`,
            ]"
          />
          {{ $config.tasks.statusLabels[item.status.id] }}
        </div>
      </template>
      <template v-slot:item.priority="{ item }">
        <div class="d-flex align-center text_color_19--text font-weight-medium">
          <UIImage
            :name-path="`icons/ic-${$config.tasks.priorityLabels[
              item.priority.id
            ]?.toLowerCase()}-priority.svg`"
            class="mr-4"
            height="14"
            width="14"
          ></UIImage>
          {{ item.priority.label }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <TaskTableMenu
          :task="item"
          @update="getInfo"
          @openModal="$emit('selectActiveTask', $event)"
        ></TaskTableMenu>
      </template>
      <template v-slot:footer>
        <div
          class="d-flex justify-center"
          v-if="
            tableItems?.length &&
            pagination.total > tableItems.length &&
            pagination.current_page < pagination.last_page
          "
        >
          <UiBtn
            class="accent-100--text text-body font-weight-medium text-decoration-underline underline-offset-1"
            text
            :loading="loadingData"
            @click="loadMore"
          >
            View More
          </UiBtn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import UIChip from "@/components/UI/UIChip.vue";
import { differenceInDays, format, formatDistance } from "date-fns";
import UiBtn from "@/components/UI/UiBtn.vue";
import tableColumns from "@/mixins/tableColumns";
import UIImage from "@/components/UI/UIImage.vue";
import TaskTableMenu from "@/views/tasks/TaskTableMenu.vue";
import IconCircleFilled from "@/components/icons/IconCircleFilled.vue";
import TasksFilter from "@/views/tasks/TasksFilter.vue";
import UiSelectedIndicator from "@/components/UI/UiSelectedIndicator.vue";
import { capitalizeText } from "@/utils/helpers";

export default {
  components: {
    UiSelectedIndicator,
    TasksFilter,
    IconCircleFilled,
    TaskTableMenu,
    UIImage,
    UiBtn,
    UIChip,
  },
  mixins: [tableColumns],

  data() {
    const ITEMS_PER_PAGE = 10;

    return {
      taskActionsMenu: {
        menu: false,
        x: 0,
        y: 0,
      },
      tableName: "task-table",
      selected: [],
      expanded: [],
      defaultHeaders: [
        {
          text: "Title",
          sortable: true,
          width: "180",
          value: "title",
          hide: false,
        },
        {
          text: "Assigned to",
          sortable: true,
          width: "80",
          value: "assignee",
          hide: false,
        },
        {
          text: "Start Date",
          sortable: true,
          width: "100",
          value: "start_date",
          hide: false,
        },
        {
          text: "Due Date",
          sortable: true,
          width: "100",
          value: "due_date",
          hide: false,
        },
        {
          text: "Space",
          sortable: true,
          width: "130",
          value: "space",
          hide: false,
        },
        {
          text: "Status",
          sortable: true,
          width: "100",
          value: "status",
          hide: false,
        },

        {
          text: "Priority",
          sortable: true,
          width: "100",
          value: "priority",
          hide: false,
        },
        {
          text: "",
          sortable: true,
          width: "40",
          value: "actions",
          hide: false,
        },
      ],
      tableItems: [],
      sortModel: {
        sortBy: null,
        sortMode: null,
      },
      loadingData: false,
      ITEMS_PER_PAGE,
      FILTER_ITEMS_COUNT: 15,
      pagination: {
        current_page: 1,
        from: 0,
        last_page: 0,
        per_page: 10,
        to: 0,
        total: 0,
      },
      debounce: null,
      filtersState: {
        space: {
          loading: false,
          search: "",
          page: 1,
          data: [],
          meta: null,
        },
        status: {
          loading: false,
          search: "",
          page: 1,
          data: [],
          meta: null,
        },
        priority: {
          loading: false,
          search: "",
          page: 1,
          data: [],
          meta: null,
        },
        assignee: {
          loading: false,
          search: "",
          page: 1,
          data: [],
          meta: null,
        },
        due_date: {
          loading: false,
          search: "",
          data: [
            { title: "Late", id: 1 },
            { title: "Today", id: 2 },
            { title: "Tomorrow", id: 3 },
            { title: "This Week", id: 4 },
            { title: "Later", id: 5 },
          ],
        },
        search: "",
      },
      columnDebounce: null,
    };
  },
  computed: {
    queryParams() {
      return {
        search: this.$route.query.search || "",
        count: this.$route.query.count || "",
        space: Array.isArray(this.$route.query.space)
          ? this.$route.query.space.map((el) => Number(el))
          : this.$route.query.space
          ? [Number(this.$route.query.space)]
          : [],
        status: Array.isArray(this.$route.query.status)
          ? this.$route.query.status.map((el) => Number(el))
          : this.$route.query.status || this.$route.query.status == "0"
          ? [Number(this.$route.query.status)]
          : [],
        priority: Array.isArray(this.$route.query.priority)
          ? this.$route.query.priority.map((el) => Number(el))
          : this.$route.query.priority || this.$route.query.priority == "0"
          ? [Number(this.$route.query.priority)]
          : [],
        assignee: Array.isArray(this.$route.query.assignee)
          ? this.$route.query.assignee.map((el) => Number(el))
          : this.$route.query.assignee || this.$route.query.assignee == "0"
          ? [Number(this.$route.query.assignee)]
          : [],
        due_date: Array.isArray(this.$route.query.due_date)
          ? this.$route.query.due_date.map((el) => Number(el))
          : this.$route.query.due_date || this.$route.query.due_date == "0"
          ? [Number(this.$route.query.due_date)]
          : [],
      };
    },
  },
  watch: {
    sortModel: {
      async handler() {
        await this.getInfo();
      },
      deep: true,
    },
    queryParams: {
      handler() {
        this.selected = [];
      },
    },
    headers: {
      async handler(val) {
        clearTimeout(this.columnDebounce);
        this.columnDebounce = setTimeout(async () => {
          await this.updateColumnSettings(val);
        }, 800);
      },
      deep: true,
    },
  },
  async mounted() {
    const promises = [
      this.getStatuses(),
      this.getPriority(),
      this.getAssignee(),
      this.getInfo(),
      this.getColumnSettings(),
    ];
    await Promise.all(promises);
    this.isDataFetched = true;
  },
  methods: {
    format,
    formatDate(date) {
      return formatDistance(new Date(date), new Date(), {
        addSuffix: true,
      }).replace("about ", "");
    },
    customSort(items, field, isDescending) {
      if (field.length && isDescending.length) {
        this.sortModel.sortBy = field[0];
        this.sortModel.sortMode = isDescending[0] ? "desc" : "asc";
      } else {
        this.sortModel.sortBy = null;
        this.sortModel.sortMode = null;
      }
      return items;
    },

    async loadMore() {
      this.pagination.per_page += this.ITEMS_PER_PAGE;
      await this.$router.replace({
        query: {
          ...this.$route.query,
          count: this.pagination.per_page,
        },
      });
      await this.getInfo();
    },

    async getInfo() {
      this.loading = true;
      const sortParam =
        this.sortModel.sortBy && this.sortModel.sortMode
          ? `${this.sortModel.sortBy}|${this.sortModel.sortMode}`
          : "";
      const tasks = await this.$api.task.list(`dashboard/tasks`, {
        ...this.queryParams,
        count: this.queryParams.count ?? this.pagination.per_page,
        sort_by: sortParam,
      });

      this.tableItems = tasks.data?.map((task) =>
        this.formatTaskFromBackend(task)
      );
      this.pagination.current_page = tasks.meta.current_page;
      this.pagination.last_page = tasks.meta.last_page;
      this.pagination.total = tasks.meta.total;
      this.loading = false;
    },

    formatTaskFromBackend(task) {
      return {
        ...task,
        due_date: new Date(task.due_date).getTime(),
        loading: false,
      };
    },

    async getStatuses() {
      this.filtersState.status.data = Object.entries(
        this.$config.tasksConfig.tasks.statusLabels
      ).map(([key, value]) => ({
        id: Number(key),
        title: value,
      }));
    },

    async getPriority() {
      this.filtersState.priority.data = Object.entries(
        this.$config.tasksConfig.tasks.priorityLabels
      ).map(([key, value]) => ({
        id: Number(key),
        title: value,
      }));
    },
    async getAssignee(search) {
      this.filtersState.assignee.loading = true;

      try {
        const params = {
          count: this.FILTER_ITEMS_COUNT,
          search: search,
        };

        const res = await this.$api.dashboard.getFilterAssignee(params);

        this.filtersState.assignee.data = res.data.map((el) => ({
          ...el,
          title: capitalizeText(el.email),
        }));
      } catch (error) {
        console.error(error);
      } finally {
        this.filtersState.assignee.loading = false;
      }
    },

    updateFilter() {
      this.getInfo(false);
    },

    loadFilters(data) {
      if (data.type === this.$config.tasksConfig.filter_slugs.assignee) {
        this.getAssignee(data.search);
      }
    },
    refetchFilters() {
      Promise.all([this.getAssignee()]);
    },
    dueDaysLeft(designDate) {
      const now = new Date();
      now.setHours(0, 0, 0, 0);

      const dueDate = new Date(designDate);
      dueDate.setHours(0, 0, 0, 0);

      const daysDiff = differenceInDays(dueDate, now);

      if (daysDiff === 0) {
        const localDate = new Date(designDate).toLocaleString("en-US", {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        return {
          value: format(new Date(localDate), "h:mm a"),
          prefix: "Due:",
          suffix: "",
          backgroundColor: "primary-10",
          textColor: "error-70--text",
        };
      }

      if (daysDiff < 0) {
        return {
          value: Math.abs(daysDiff),
          prefix: "Overdue:",
          suffix: Math.abs(daysDiff) > 1 ? "days" : "day",
          backgroundColor: "error-70",
          textColor: "white--text",
        };
      }

      return {
        value: daysDiff,
        prefix: "Due:",
        suffix: daysDiff > 1 ? "days" : "day",
        backgroundColor: "primary-10",
        textColor: "warning-70--text",
      };
    },
    async setAsComplete(task_id, project_id) {
      await this.$api.task.updateStatus(project_id, task_id, { status: 5 });
      await this.getInfo();
    },
  },
};
</script>

<style scoped lang="scss">
.task-table {
  :deep(.v-data-table__wrapper) {
    padding-bottom: 10px;
  }
  :deep(table) {
    thead {
      background: transparent;
      height: 21px !important;

      &::after {
        content: "";
        display: block;
        height: 15px; // Задайте высоту, которую вы хотите для отступа
        background: var(--v-gray-0-base);
      }
      th {
        height: 21px !important;
        border-bottom: 1px solid var(--v-gray-30-base) !important;
        font-weight: 400;
        font-size: 10px !important;
        color: var(--v-gray-80-base) !important;
        padding: 0 !important;
        &:first-child {
          padding: 9px 2px;
        }

        &.sortable {
          i {
            margin-left: 2px !important;
            font-size: 10px !important;
            &::before {
              content: url("~@/assets/images/sort.svg");
              width: 10px !important;
              height: 10px !important;
            }
          }
        }
      }
      .v-data-table__progress {
        th {
          height: auto !important;
          background: transparent;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          border-radius: 0 !important;
          border-bottom: 1px solid var(--v-gray-30-base);
          &.text-start {
            font-size: 12px;
            color: var(--v-gray-100-base);
            padding: 9px 2px;
          }
          &:first-child {
            padding-right: 24px !important;
          }
        }
      }
      .v-data-table__expanded__content {
        box-shadow: none;

        td {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
.header-content {
  width: fit-content;
}
.product-img {
  width: 26px;
  height: 26px;
  margin-right: 10px;
  :deep(img) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.inner {
    width: 18px;
    height: 18px;
    margin-left: 6px;
  }
}
.inner-block {
  max-height: 30px;
  min-height: 30px;
}
.max-w-200 {
  max-width: calc(200px); // TODO, use JS for dynamic width
}
.max-w-200 {
  max-width: calc(200px); // TODO, use JS for dynamic width
}
.extlink-icon {
  margin-bottom: -2px;
}

.due-date-label {
  max-height: 18px;
  padding: 4px !important;
  border-right: 3px !important;
}
.custom-border {
  border: 1px solid var(--v-accent-100-base) !important;
  background: var(--v-gray-30-base) !important;
  border-color: var(--v-accent-100-base) !important;
}
</style>
